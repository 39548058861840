<template>
  <div class="container-fluid d-flex flex-column flex-grow-1 mt-3">
    <ListingComponent ref="FeedBack"
                      class="bg-white"
                      :addNewButton="addNewButton"
                      :requestProvider="requestProvider"
                      :columnConfig="columnConfig">
    </ListingComponent>
    <b-modal v-model="isDeleteModal"
             :no-close-on-backdrop="true"
             centered title="Delete modal"
             @cancel="closeDeleteModal"
             @ok="removeItem">
      <form>
        <h3 class="text-black-50">Do you really want to delete</h3>
      </form>
    </b-modal>
  </div>
</template>
<script>
  import ListingComponent from '@/components/listing/ListingComponent';

  export default {
    name: 'FeedBackPage',
    data () {
      return {
        defultImage: require('../../assets/img/user-default.png'),
        id: null,
        isDeleteModal: false,
        columnConfig: [
          {
            columnCode: 'action',
            columnName: 'Action',
            value: (item) => {
              return (
                  <b-dropdown variant="link" class="listing-action">
                  <b-dropdown-item onClick={() => this.showDeleteModal(item)}>Delete</b-dropdown-item>
              </b-dropdown>
            )
            }
          },
          {
            columnCode: 'IMAGE',
            columnName: 'Image',
            align: 'left',
            customizeColumn: true,
            value: item => {
              if (!item.image)
                return <img className="profile-img-sm rounded-circle mb-2" style="height: 40px;" src={this.defultImage}/>
              return <img className="profile-img-sm rounded-circle mb-2" style="height: 40px;" src={item.image.imageURL}/>
            }
          },
          {
            columnCode: 'FIRST_NAME',
            columnName: 'First Name',
            align: 'left',
            value: item => {
              return item.user ? item.user.firstName : 'n/a';
            }
          },
          {
            columnCode: 'LAST_NAME',
            columnName: 'Last Name',
            align: 'left',
            value: item => {
              return  item.user ? item.user.lastName : 'n/a';
            }
          },
          {
            columnCode: 'SUBJECT',
            columnName: 'Subject',
            align: 'left',
            value: item => {
              return item.subject;
            }
          },
          {
            columnCode: 'TEXT',
            columnName: 'Text',
            align: 'left',
            value: item => {
              return item.text
            }
          }
        ]
      }
    },
    methods: {
      requestProvider (payload) {
        return this.axios.get('feedback/list', payload)
      },
      addNewButton () {
        return (
            <router-link class="btn btn-success mr-3" to="#"><i class="fas fa-plus-circle"></i></router-link>


      )
      },
      closeDeleteModal () {
        this.id = null
        this.$nextTick(() => {
          this.isDeleteModal = false
        })
      },
      showDeleteModal (item) {
        if (!item.id) {
          this.$toastr.error('Users not found')
        }
        this.id = item.id
        this.isDeleteModal = true
      },
      removeItem () {
        if (!this.id) {
          return
        }
        this.axios.delete('feedback/item/', {params: {id: this.id}})
            .then((result) => {
              this.$refs.FeedBack.doRequest()
              if (result.data && result.data.status === 'success') {
                this.$toastr.success('FeedBack successfully deleted')
              }
              this.isDeleteModal = false
            })
      }
    },
    components: {
      ListingComponent
    }
  };
</script>

<style scoped>

</style>